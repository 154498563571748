/* Cria uma funcção que pode ser usada a nivel global */
import label from '../config/label.json';
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom/client';


export const GFN = {

    key: null,

    root: ReactDOM.createRoot(document.getElementById('app')),
    renderElement: (elem, render)=>{

        const root = ReactDOM.createRoot(elem);
        console.log(root);

        root.render(render);
    },


    modal: (is_close) => {
        const modal = document.createElement('div');
        modal.classList.add('modal');
        document.getElementById('app').appendChild(modal);
    
        const content = document.createElement('div');
        content.classList.add('content-modal');
        modal.appendChild(content);
    
        const close = document.createElement('span');
        close.innerHTML = '&times;'
        close.addEventListener('click', () => { 
            modal.remove();
        })
        close.classList.add('modal-Close');
          
        if(is_close){
            content.appendChild(close);
        }

        const main = document.createElement('div');
        content.appendChild(main);
    
        return [modal, content, main, close];
    },



    formatNumber: (number)=>{
        // Limpa o número, removendo caracteres não numéricos
        number = number.replace(/\D/g, '');
      
        // Se o número não tiver 11 dígitos, assume-se que não possui nono dígito
        if (number.length !== 11) {
          return `+${number.substr(0, 2)} ${number.substr(2, 2)} ${number.substr(4, 4)}-${number.substr(8)}`;
        }
      
        // Se tiver 11 dígitos, adiciona o nono dígito
        return `+${number.substr(0, 2)} ${number.substr(2, 1)} ${number.substr(3, 4)}-${number.substr(7)}`;
    },



    getAssinaturaDados: async(assinaturaID)=>{
        var formdata = new FormData();
        formdata.append("assinaturaID", assinaturaID);
        
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
      
        return await fetch(`${label.backend}/api/assas/recuperarAssinatura`, requestOptions)
        .then(response => response.json())
        .then(result =>{return result})
        .catch(error => console.log('error', error));
    },



    /* Notificação */
    alert: (ico, msg) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: ico,
            title: msg
        })
    },




};
  