import React, { useRef } from 'react';
import ReactDOM from 'react-dom/client';
import '../../css/painel.css'
import {HiMenu} from 'react-icons/hi';
import { GFN } from '../globalFunctions';
import label from '../../config/label.json';
import Afiliados from '../afiliados/afiliados';
import Painel from './painel';



const menu = () =>{

  const [modal, content, main, close] = GFN.modal(false);

  modal.setAttribute('tag', 'header-nav');
  content.setAttribute('tag', 'nav-content');
  content.classList.add('header-menuBar');

  const login = (
    <div style={{paddingTop:'20px'}}>
      <h1 style={{textAlign:'start'}}>Menu</h1>
       
      <ul className='menuList'>
         
        <li onClick={()=>{menuFunctions.painel(modal)}}>Painel</li>    
          
        <li onClick={()=>{modal?.remove(); menuFunctions.dados()}}>Dados Gerais</li>

        <li onClick={menuFunctions.sair}>Sair</li>

      </ul>
    </div>
  );


  ReactDOM.createRoot(main).render(login);

  /* <li onClick={()=>{modal?.remove(); menuFunctions.servidor()}}>Servidor</li> */

}


const menuFunctions = {

  sair:()=>{
    sessionStorage.clear();
    window.location.reload();
  },



  dados: async()=>{
    const [modal, content, main, close] = GFN.modal(true);

    main.setAttribute('style', 'padding: 20px 0;');

    var formdata = new FormData();
    formdata.append("key", sessionStorage.getItem('key'));
    formdata.append("afiliado", sessionStorage.getItem('user'));
  
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
   
    const users = await fetch(`${label.backend}/api/allUsers/afiliado`, requestOptions)
    .then(response => response.json())
    .then(result => { return result.response.users})
    .catch(error => console.log('error', error));
    

    const mainContent = ( 
      <>
        <h1>Dados Gerais</h1>

        <div className='dados-gerais'>

          <span>Total de usuarios registrados: {users.length}</span>

          <span>Usuarios em teste: {users.filter(user => user.status === 0).length}</span>
          
          <span>Usuarios gratuitos: {users.filter(user => user.status === 1).length}</span>

          <span>Usuarios que possuem assinatura: {users.filter(user => user.status === 2).length}</span>

          <span>Usuarios bloqueados: {users.filter(user => user.status === 3).length}</span>


          <span>Rentabilidade mensal prevista: <strong id='val'>Aguarde!</strong></span>


        </div>
      </>
    )

    ReactDOM.createRoot(main).render(mainContent);


    setTimeout(async()=>{
      document.querySelector('#val').innerHTML = await menuFunctions.mensalPrevista(users);
     
    },1500)
  },



  mensalPrevista: async(users)=>{
     
    let totalMensal = 0;

    for(let user of users){
        
      if(user.status == 2 && user.idAssas){
          
        let ass = JSON.parse(user.idAssas);
        let res = await GFN.getAssinaturaDados(ass.assinaturaID);

        totalMensal = totalMensal + res.value
      }

    }
    
    let percentage = parseFloat(sessionStorage.getItem('percentage')) / 100;
    if (!isNaN(percentage)) {
      totalMensal *= percentage;
    }
     

    return totalMensal.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
  },



  saldoAtual:async()=>{
  
    let res = await fetch(`${label.backend}/api/assas/recuperarSaldo`)
    .then(response => response.json())
    .then(result =>{return result.balance})
    .catch(error => console.log('error', error));

    return res.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
  },



  afiliados: async(modal)=>{
     
    modal?.remove();
    GFN.root.render(<Afiliados />)
  },



  painel: async(modal)=>{

    modal?.remove();
    GFN.root.render(<Painel />)
  }

}



export default function Header(){

  const header = (

    <header>

      <div className="header">

        <div>
          <img src='images/logo.svg'/>
        </div>

        <div tag="menu" onClick={menu}>
          <HiMenu/>
        </div>

      </div>

    </header>

  );

  return header;
}